import React, { useCallback, useContext, useEffect, useState } from "react";
import { useSession } from "./SessionProvider";
import { getQuizWinners } from "services/quiz";

const QuizContext = React.createContext({
    quizWinners: [],
    filter: null,
    getQuizRecords: () => [],
    updateFilter: () => {},
});

export const QuizProvider = ({ children }) => {
    const { user } = useSession();
    const [quizWinners, setQuizWinners] = useState([]);
    const [filter, setFilter] = useState({
        year: "",
        month: "",
    });

    const getQuizRecords = useCallback(async (filter) => {
        try {
            const inputFilter = filter || {};
            const quizData = await getQuizWinners(inputFilter);

            setQuizWinners(quizData.data);
        } catch (e) {
            console.log(e.message);
        }
    }, []);

    function updateFilter(filter) {
        setFilter(filter);
    }

    useEffect(() => {
        if (filter && user?.id) {
            getQuizRecords(filter);
        }
    }, [getQuizRecords, filter, user]);

    return (
        <QuizContext.Provider
            value={{
                getQuizRecords,
                updateFilter,
                quizWinners,
                filter,
            }}
        >
            {children}
        </QuizContext.Provider>
    );
};

export function useQuiz() {
    const quizContext = useContext(QuizContext);

    return quizContext;
}
