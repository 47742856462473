import api from "./interceptor";

export async function getQuizWinners(date) {
    try {
        const { year, month } = date;
        const response = await api.get("/quiz/winners", {
            params: { year, month },
        });

        return response.data;
    } catch (e) {
        console.log(e.message);
    }
}
