import React from "react";
import Layout from "../components/layout/Layout";
import Navbar from "./layout/Navbar";
import { useQuiz } from "providers/QuizProvider";
import { format } from "date-fns";
import { numberFormatter } from "utils/number.util";

const QuizWinners = () => {
    const { quizWinners, updateFilter } = useQuiz();

    function parseMonth(monthInput) {
        const year = Math.floor(monthInput / 100);
        const month = monthInput - year * 100 - 1;

        return format(new Date(year, month), "yyyy-MM");
    }

    return (
        <>
            <Layout>
                <Navbar updateFilter={updateFilter} />
                <div style={{ marginTop: "50px" }}></div>
                <div className="rows">
                    <div className="containers table-responsive">
                        {/* <!--Table--> */}
                        <table
                            className="display nowrap ns z-depth-1 table table-striped table-bordered row-border order-column table-hover"
                            style={{ width: "100%" }}
                        >
                            <thead>
                                <tr>
                                    <th className="black-text text-darken-2">
                                        Month
                                    </th>
                                    <th className="black-text text-darken-2">
                                        Name
                                    </th>
                                    <th className="black-text text-darken-2">
                                        MSISDN
                                    </th>
                                    <th className="black-text text-darken-2">
                                        Total Points
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {quizWinners.map((record) => (
                                    <tr key={record.id}>
                                        <td
                                            className="ns"
                                            style={{ textAlign: "center" }}
                                        >
                                            {parseMonth(record.monthName)}
                                        </td>
                                        <td
                                            className="ns"
                                            style={{ textAlign: "center" }}
                                        >
                                            {record.user.name}
                                        </td>
                                        <td
                                            className="ns"
                                            style={{ textAlign: "center" }}
                                        >
                                            {record.user.mobileNumber}
                                        </td>
                                        <td
                                            className="ns"
                                            style={{ textAlign: "center" }}
                                        >
                                            {numberFormatter.format(
                                                record.totalPoints
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan={14}>
                                        Showing {quizWinners.length} of{" "}
                                        {quizWinners.length} record(s)
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </Layout>
        </>
    );
};

export default QuizWinners;
